*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
input{
    margin: 2%;
}
h2{
    margin: 2%;
}
.formParent{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.form-group{
    width:300px;
    position: relative;
    left: -80px;
}

.photoEdit{
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

@media screen and (min-width: 880px) {
    .Parent{
        width: 100%;
        display: flex;
        height: 100%;
        justify-content: center;
        flex-wrap: wrap;
    }
    .photoEdit{
        width: 50%;
        height: 100%;
    }
    .formParent{
        width: 50%;
    }
    .form-group{
        width:100%;
        margin: 0 auto;
        position: relative; 
        left: 0px;
    }
  }
