.Actuality_container{
    position: relative;
    top: 10px;
    justify-content: center;
    align-items: center;
}
.CircularIndeterminate{
    position: relative;
    top: 10px;
}
.publications {
  width: 100%;
  padding: 30px;
}
iframe {
  margin: 0 auto;
}

@media screen and (max-width: 576px) {
  iframe {
    width: 90%;
  }
}
